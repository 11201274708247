<template>
  <div>
    <v-container style="max-width: 900px">
      <ValidationObserver ref="form">
        <v-form @submit.prevent="handleSubmitForm">
          <v-card :loading="loading" :disabled="loading">
            <v-toolbar color="primary" dark prominent flat>
              <v-toolbar-title> {{ form.name || $capitalize($tc('model.teams_teams_title')) }} </v-toolbar-title>
            </v-toolbar>
            <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
            <v-card-text v-if="!!form.id">
              <v-row dense>
                <v-col class="text-right">
                <ScopeProvider scope="teams.delete">
                  <v-btn @click="handleDestroyTeam()" color="red" dark>
                    <v-icon left>mdi-delete</v-icon> {{ $capitalize($tc('model.teams_delete_team_button')) }}</v-btn
                  >
                  </ScopeProvider>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col>
                <v-card-title> {{ $capitalize($tc('model.teams_data_title')) }} </v-card-title>
                <v-card-subtitle></v-card-subtitle>
                <v-card-text>
                  <ValidationProvider
                    name="name"
                    rules="required|max:30"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="form.name"
                      :label="
                        $capitalize($tc('model.teams_name_label'))
                      "
                      counter="30"
                      name="name"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                  <v-select
                    :label="
                      $capitalize($tc('model.teams_choose_a_leader'))
                    "
                    :items="managers"
                    item-text="user_name"
                    item-value="id"
                    clearable
                    v-model="form.leader_id"
                  ></v-select>
                </v-card-text>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-card-title>
                  {{ $capitalize($tc('model.teams_managers_title')) }}
                  <v-spacer></v-spacer>
                  <v-btn v-if="!form.id" @click="getManagers()" icon>
                    <v-icon> mdi-refresh </v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    type="warning"
                    text
                    v-if="getManagerVerificationAlert()"
                  >
                    {{ $capitalize($tc('model.teams_managers_relocated_message')) }}
                  </v-alert>
                </v-card-text>
                <v-card-subtitle>
                   {{ $tc("model.teams_selected_managers", form.managers.length) }}
                </v-card-subtitle>
                <v-data-table
                  v-model="form.managers"
                  :show-select="!!!form.id"
                  :read-only="!!!form.id"
                  :items="manager_list"
                  :headers="manager_table_headers"
                  :loading="managers_loading"
                >
                  <template v-slot:header.user_name>
                    {{ $capitalize($tc("model.teams_name_table_column", 1)) }}
                  </template>
                  <template v-slot:header.team>
                    {{ $capitalize($tc("model.teams_team_table_column", 1)) }}
                  </template>
                  <template v-slot:header.lead_type>
                    {{ $capitalize($tc("model.teams_service_column_managers_table", 1)) }}
                  </template>

                  <template v-slot:item.lead_type="{ item }">
                    <div>
                      <v-chip small>
                        {{ item.lead_type.description }}
                      </v-chip>
                    </div>
                  </template>
                  <template v-slot:item.team="{ item }">
                    <div>
                      <v-chip
                        v-if="item.team_name"
                        small
                        class="mr-1"
                        color="indigo"
                        dark
                        label
                      >
                        {{ item.team_name }}
                      </v-chip>
                      <v-chip v-else small class="mr-1" color="grey" dark label>
                        {{ $capitalize($tc('model.teams_no_team')) }}
                      </v-chip>
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-card-title>
                  {{ $capitalize($tc('model.teams_units_title')) }}
                  <v-spacer></v-spacer>
                  <v-btn v-if="!form.id" @click="getUnities()" icon>
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    type="warning"
                    text
                    v-if="getUnityVerificationAlert()"
                  >
                    {{ $capitalize($tc('model.teams_units_relocated_message')) }}
                  </v-alert>
                </v-card-text>
                <v-card-subtitle>
                 {{ ($tc('model.teams_units_selected', form.unities.length)) }}
                </v-card-subtitle>
                <v-data-table
                  :items="unities"
                  :headers="unitiy_table_headers"
                  :show-select="!!!form.id"
                  multiple
                  v-model="form.unities"
                  item-value="id"
                  :loading="unities_loading"
                >
                  <template v-slot:header.name>
                    {{ $capitalize($tc("model.teams_name_table_column", 1)) }}
                  </template>
                  <template v-slot:header.team>
                    {{ $capitalize($tc("model.teams_team_table_column", 1)) }}
                  </template>
                  <template v-slot:header.service_types>
                    {{ $capitalize($tc("model.teams_services_column_units_table", 1)) }}
                  </template>

                  <template v-slot:item.service_types="{ item }">
                    <v-chip
                      v-for="service_type in item.service_types"
                      :key="service_type.id"
                      small
                      class="mr-1"
                    >
                      {{ service_type.description }}
                    </v-chip>
                  </template>

                  <template v-slot:item.team="{ item }">
                    <v-chip
                      v-if="item.team_name"
                      small
                      class="mr-1"
                      color="indigo"
                      dark
                      label
                    >
                      {{ item.team_name }}
                    </v-chip>
                    <v-chip v-else small class="mr-1" color="grey" dark label>
                      {{ $capitalize($tc('model.teams_no_team')) }}
                    </v-chip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="$router.push('/teams')" text class="mr-2">
                {{ $capitalize($tc('model.teams_cancel_button')) }}
              </v-btn>
              <v-btn type="submit" color="primary" :loading="loading">
                {{ $capitalize($tc('model.teams_confirm_button')) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-container>
  </div>
</template>

<script>
import { index as indexManagers } from "@/services/service_managers";
import { index as indexUnities } from "@/services/unities";
import {
  store as storeTeam,
  update as updateTeam,
  show as showTeam,
  destroy as destroyTeam,
} from "@/services/teams";
import ScopeProvider from "@/components/ScopeProvider";

export default {
  components: { ScopeProvider },
  data: function() {
    return {
      breadcrumbs: [
        {
          text: this.$capitalize(
            this.$tc("model.teams_teams_tab")
          ),
          disabled: false,
          to: { name: "teams" },
          exact: true,
        },
        {
          text: this.$capitalize(
            this.$tc("model.teams_new_tab")
          ),
          disabled: true,
        },
      ],
      form: {
        managers: [],
        unities: [],
      },
      managers: [],
      manager_table_headers: [
        { text: "Nome", value: "user_name" },
        {
          text: "Equipe",
          value: "team",
        },
        { text: "Serviço", value: "lead_type", sortable: false },
      ],
      unities: [],
      total_unities: 0,
      managers_loading: false,
      unities_loading: false,
      unitiy_table_headers: [
        { text: "Nome", value: "name" },
        {
          text: "Equipe",
          value: "team",
        },
        { text: "Serviços", value: "service_types", sortable: false },
      ],
      loading: false,
    }
  },
  methods: {
    async handleSubmitForm() {
      const validation = await this.$refs.form.validate();

      if (!validation) return;

      this.loading = true;

      try {
        if (this.form.id) {
          await this.updateTeam();
          this.$store.commit("success", "Equipe atualizada com sucesso");
        } else {
          await this.createTeam();
          this.$store.commit("success", "Equipe cadastrada.");
        }
        this.$router.push("/teams");
      } catch (error) {
        if (error.response.status == 422) {
          this.$refs.form.setErrors(error.response.data.errors);
          this.$store.commit("error", "ERRO: Corrija o formulário.");
          return;
        }

        this.$store.commit("error", error);
      } finally {
        this.loading = false;
      }
    },
    async getManagers() {
      this.managers_loading = true;
      try {
        const response = await indexManagers({ list: true });
        this.managers = response;
      } catch (error) {
        this.commit("sendMessage", {
          message: "Falha ao buscar gestores",
          color: "red",
        });
      } finally {
        this.managers_loading = false;
      }
    },
    getManagerVerificationAlert() {
      return (
        this.form.managers.find((manager) => manager.team_id != null) &&
        !!!this.form.id
      );
    },
    getUnityVerificationAlert() {
      return (
        this.form.unities.find((unity) => unity.team_id != null) &&
        !!!this.form.id
      );
    },
    async getUnities() {
      this.unities_loading = true;
      try {
        const response = await indexUnities({
          paginate: true,
        });
        this.unities = response.data;
        this.total_unities = response.total;
      } catch (error) {
        this.$store.commit("sendMessage", {
          message: "Falha ao buscar unidades.",
          color: "red",
        });
      } finally {
        this.unities_loading = false;
      }
    },
    async createTeam() {
      const managers = this.form.managers.map((manager) => manager.id);
      const unities = this.form.unities.map((unity) => unity.id);

      const payload = {
        ...this.form,
        managers,
        unities,
      };

      const response = await storeTeam(payload);
    },
    async updateTeam() {
      const managers = this.form.managers.map((manager) => manager.id);
      const unities = this.form.unities.map((unity) => unity.id);
      const id = this.form.id;

      const payload = {
        ...this.form,
        managers,
        unities,
      };

      const response = await updateTeam(payload, id);
    },
    async showTeam(id) {
      const response = await showTeam(id);

      this.managers = response.managers;
      this.unities = response.unities;
      this.form = response;
    },
    handleDestroyTeam() {
      this.$store.dispatch("confirm_dialog/confirm", {
        title: "Excluir equipe?",
        description: "A ação não poderá ser desfeita.",
        positive_action: () => this.destroyTeam(this.form.id),
      });
    },
    async destroyTeam(id) {
      try {
        await destroyTeam(id);
        this.$store.commit("success", "Equipe excluída");
        this.$router.replace("/teams");
      } catch (error) {
        this.$store.commit("error", error);
      }
    },
  },
  computed: {
    manager_list: function () {
      return this.form.id
        ? this.managers.filter((manager) =>
            this.form.managers.map((m) => m.id).includes(manager.id)
          )
        : this.managers;
    },
  },
  created() {
    const id = this.$route.params.id;

    if (!!id) {
      this.form.id = id;
      this.showTeam(id);
    } else {
      this.getUnities();
    }

    this.getManagers();
  },
};
</script>